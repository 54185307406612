"use strict";
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var moduleEnd = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    var block = context.blockStack.pop();
    if (!block || !block.isModuleBlock) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.MISSING_BLOCK_START_INSTRUCTION, line, context);
    }
    return { byteCode: [], context: context };
};
exports["default"] = moduleEnd;
