"use strict";
exports.__esModule = true;
var errors_1 = require("../errors");
var instructionHelpers_1 = require("../wasmUtils/instructionHelpers");
var utils_1 = require("../utils");
var branchIfTrue = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    if (!line.arguments[0]) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.MISSING_ARGUMENT, line, context);
    }
    if (line.arguments[0].type === "identifier" /* ArgumentType.IDENTIFIER */) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.EXPECTED_VALUE, line, context);
    }
    var operand = context.stack.pop();
    if (!operand) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSUFFICIENT_OPERANDS, line, context);
    }
    if (!operand.isInteger) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.EXPECTED_INTEGER_OPERAND, line, context);
    }
    return { byteCode: (0, instructionHelpers_1.br_if)(line.arguments[0].value), context: context };
};
exports["default"] = branchIfTrue;
