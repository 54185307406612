"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var _const = function (line, context) {
    var _a;
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    if (!line.arguments[0] || !line.arguments[1]) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.MISSING_ARGUMENT, line, context);
    }
    if (line.arguments[0].type === "literal" /* ArgumentType.LITERAL */) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.EXPECTED_IDENTIFIER, line, context);
    }
    var value = { value: 0, isInteger: true };
    if (line.arguments[1].type === "identifier" /* ArgumentType.IDENTIFIER */) {
        if (typeof context.namespace.consts[line.arguments[1].value] !== 'undefined') {
            value = context.namespace.consts[line.arguments[1].value];
        }
        else {
            throw (0, errors_1.getError)(errors_1.ErrorCode.UNDECLARED_IDENTIFIER, line, context);
        }
    }
    else {
        value = line.arguments[1];
    }
    return {
        byteCode: [],
        context: __assign(__assign({}, context), { namespace: __assign(__assign({}, context.namespace), { consts: __assign(__assign({}, context.namespace.consts), (_a = {}, _a[line.arguments[0].value] = value, _a)) }) })
    };
};
exports["default"] = _const;
