"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var instructionHelpers_1 = require("../wasmUtils/instructionHelpers");
var compiler_1 = require("../compiler");
var loadFloat = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    var operand = context.stack.pop();
    if (!operand) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSUFFICIENT_OPERANDS, line, context);
    }
    if ((0, utils_1.areAllOperandsIntegers)(operand)) {
        if (operand.isSafeMemoryAddress) {
            context.stack.push({ isInteger: false, isNonZero: false });
            return { byteCode: (0, instructionHelpers_1.f32load)(), context: context };
        }
        else {
            context.stack.push(operand);
            var tempVariableName = '__loadAddress_temp_' + line.lineNumber;
            // Memory overflow protection.
            var ret = (0, compiler_1.parseSegment)(__spreadArray([
                "local int ".concat(tempVariableName),
                "localSet ".concat(tempVariableName),
                "localGet ".concat(tempVariableName),
                "push ".concat(context.memoryByteSize - 1),
                'greaterThan',
                'if int',
                "push 0",
                'else',
                "localGet ".concat(tempVariableName),
                'ifEnd'
            ], __read((0, instructionHelpers_1.f32load)().map(function (wasmInstruction) {
                return "wasm ".concat(wasmInstruction);
            })), false), context);
            context.stack.pop();
            context.stack.push({ isInteger: false, isNonZero: false });
            return ret;
        }
    }
    else {
        throw (0, errors_1.getError)(errors_1.ErrorCode.ONLY_INTEGERS, line, context);
    }
};
exports["default"] = loadFloat;
