"use strict";
exports.__esModule = true;
var errors_1 = require("../errors");
var wasm = function (line, context) {
    if (!line.arguments[0]) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.MISSING_ARGUMENT, line, context);
    }
    if (line.arguments[0].type !== "literal" /* ArgumentType.LITERAL */) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.EXPECTED_VALUE, line, context);
    }
    return {
        byteCode: [line.arguments[0].value],
        context: context
    };
};
exports["default"] = wasm;
