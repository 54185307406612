"use strict";
exports.__esModule = true;
var utils_1 = require("../utils");
var errors_1 = require("../errors");
var errors_2 = require("../errors");
var compiler_1 = require("../compiler");
var div = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_2.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    var operand = context.stack.pop();
    if (!operand) {
        throw (0, errors_2.getError)(errors_1.ErrorCode.INSUFFICIENT_OPERANDS, line, context);
    }
    context.stack.push(operand);
    var defaultNonZeroValue = operand.isInteger ? '1' : '1.0';
    // If the operand is float we convert the argument to a float string.
    if (line.arguments[0] && line.arguments[0].type === "literal" /* ArgumentType.LITERAL */ && !operand.isInteger) {
        defaultNonZeroValue = line.arguments[0].value.toFixed(1);
    }
    // If the operand is integer we convert the argument to an integer string.
    if (line.arguments[0] && line.arguments[0].type === "literal" /* ArgumentType.LITERAL */ && operand.isInteger) {
        defaultNonZeroValue = line.arguments[0].value.toString();
    }
    var tempVariableName = '__ensureNonZero_temp_' + line.lineNumber;
    if (operand.isInteger) {
        var ret = (0, compiler_1.parseSegment)([
            "local int ".concat(tempVariableName),
            "localSet ".concat(tempVariableName),
            "localGet ".concat(tempVariableName),
            'equalToZero',
            'if int',
            "push ".concat(defaultNonZeroValue),
            'else',
            "localGet ".concat(tempVariableName),
            'ifEnd',
        ], context);
        context.stack.pop();
        context.stack.push({ isInteger: true, isNonZero: true });
        return ret;
    }
    else {
        var ret = (0, compiler_1.parseSegment)([
            "local float ".concat(tempVariableName),
            "localSet ".concat(tempVariableName),
            "localGet ".concat(tempVariableName),
            'equalToZero',
            'if float',
            "push ".concat(defaultNonZeroValue),
            'else',
            "localGet ".concat(tempVariableName),
            'ifEnd',
        ], context);
        context.stack.pop();
        context.stack.push({ isInteger: false, isNonZero: true });
        return ret;
    }
};
exports["default"] = div;
