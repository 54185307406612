"use strict";
exports.__esModule = true;
exports.MemoryTypes = void 0;
var MemoryTypes;
(function (MemoryTypes) {
    MemoryTypes[MemoryTypes["int"] = 0] = "int";
    MemoryTypes[MemoryTypes["int*"] = 1] = "int*";
    MemoryTypes[MemoryTypes["int**"] = 2] = "int**";
    MemoryTypes[MemoryTypes["float"] = 3] = "float";
    MemoryTypes[MemoryTypes["float*"] = 4] = "float*";
    MemoryTypes[MemoryTypes["float**"] = 5] = "float**";
})(MemoryTypes = exports.MemoryTypes || (exports.MemoryTypes = {}));
