"use strict";
exports.__esModule = true;
exports.areAllOperandsFloats = exports.areAllOperandsIntegers = exports.calculateWordAlignedSizeOfMemory = exports.isInstructionIsInsideAGroup = exports.isInstructionIsInsideAModule = exports.getElementWordSize = exports.getElementCount = exports.getMemoryStringLastByteAddress = exports.getDataStructureByteAddress = exports.getDataStructure = exports.isElementWordSizeIdentifier = exports.isElementCountIdentifier = exports.isMemoryPointerIdentifier = exports.isMemoryReferenceIdentifier = exports.isMemoryIdentifier = void 0;
var consts_1 = require("./consts");
function isMemoryIdentifier(memoryMap, name) {
    return memoryMap.has(name);
}
exports.isMemoryIdentifier = isMemoryIdentifier;
function isMemoryReferenceIdentifier(memoryMap, name) {
    return ((name.startsWith('&') && memoryMap.has(name.substring(1))) ||
        (name.endsWith('&') && memoryMap.has(name.slice(0, -1))));
}
exports.isMemoryReferenceIdentifier = isMemoryReferenceIdentifier;
function isMemoryPointerIdentifier(memoryMap, name) {
    return name.startsWith('*') && memoryMap.has(name.substring(1));
}
exports.isMemoryPointerIdentifier = isMemoryPointerIdentifier;
function isElementCountIdentifier(memoryMap, name) {
    return name.startsWith('$') && memoryMap.has(name.substring(1));
}
exports.isElementCountIdentifier = isElementCountIdentifier;
function isElementWordSizeIdentifier(memoryMap, name) {
    return name.startsWith('%') && memoryMap.has(name.substring(1));
}
exports.isElementWordSizeIdentifier = isElementWordSizeIdentifier;
function getDataStructure(memoryMap, id) {
    return memoryMap.get(id);
}
exports.getDataStructure = getDataStructure;
function getDataStructureByteAddress(memoryMap, id) {
    var memoryItem = getDataStructure(memoryMap, id);
    return memoryItem ? memoryItem.byteAddress : 0;
}
exports.getDataStructureByteAddress = getDataStructureByteAddress;
function getMemoryStringLastByteAddress(memoryMap, id) {
    var memoryItem = getDataStructure(memoryMap, id);
    return memoryItem ? memoryItem.byteAddress + (memoryItem.wordAlignedSize - 1) * consts_1.GLOBAL_ALIGNMENT_BOUNDARY : 0;
}
exports.getMemoryStringLastByteAddress = getMemoryStringLastByteAddress;
function getElementCount(memoryMap, id) {
    var memoryItem = getDataStructure(memoryMap, id);
    return memoryItem ? memoryItem.numberOfElements : 0;
}
exports.getElementCount = getElementCount;
function getElementWordSize(memoryMap, id) {
    var memoryItem = getDataStructure(memoryMap, id);
    return memoryItem ? memoryItem.elementWordSize : 0;
}
exports.getElementWordSize = getElementWordSize;
function isInstructionIsInsideAModule(blockStack) {
    for (var i = blockStack.length - 1; i >= 0; i--) {
        if (blockStack[i].isModuleBlock) {
            return true;
        }
    }
    return false;
}
exports.isInstructionIsInsideAModule = isInstructionIsInsideAModule;
function isInstructionIsInsideAGroup(blockStack) {
    return blockStack[blockStack.length - 1] && blockStack[blockStack.length - 1].isGroupBlock;
}
exports.isInstructionIsInsideAGroup = isInstructionIsInsideAGroup;
function calculateWordAlignedSizeOfMemory(memory) {
    return Array.from(memory.values()).reduce(function (accumulator, current) {
        return accumulator + current.wordAlignedSize;
    }, 0);
}
exports.calculateWordAlignedSizeOfMemory = calculateWordAlignedSizeOfMemory;
function areAllOperandsIntegers() {
    var operands = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        operands[_i] = arguments[_i];
    }
    return !operands.some(function (operand) { return !operand.isInteger; });
}
exports.areAllOperandsIntegers = areAllOperandsIntegers;
function areAllOperandsFloats() {
    var operands = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        operands[_i] = arguments[_i];
    }
    return !operands.some(function (operand) { return operand.isInteger; });
}
exports.areAllOperandsFloats = areAllOperandsFloats;
