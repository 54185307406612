"use strict";
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var compiler_1 = require("../compiler");
var dup = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    var operand1 = context.stack.pop();
    var operand2 = context.stack.pop();
    if (!operand1 || !operand2) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSUFFICIENT_OPERANDS, line, context);
    }
    var tempAName = '__swapTempA' + line.lineNumber;
    var tempBName = '__swapTempB' + line.lineNumber;
    context.stack.push(operand2);
    context.stack.push(operand1);
    return (0, compiler_1.parseSegment)([
        "local ".concat(operand1.isInteger ? 'int' : 'float', " ").concat(tempAName),
        "local ".concat(operand2.isInteger ? 'int' : 'float', " ").concat(tempBName),
        "localSet ".concat(tempAName),
        "localSet ".concat(tempBName),
        "localGet ".concat(tempAName),
        "localGet ".concat(tempBName),
    ], context);
};
exports["default"] = dup;
