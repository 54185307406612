"use strict";
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var compiler_1 = require("../compiler");
var cycle = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    var operandEndPosition = context.stack.pop();
    var operandStartPosition = context.stack.pop();
    var operandPointer = context.stack.pop();
    if (!operandEndPosition || !operandStartPosition || !operandPointer) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSUFFICIENT_OPERANDS, line, context);
    }
    if (!operandEndPosition.isInteger || !operandStartPosition.isInteger || !operandPointer.isInteger) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.EXPECTED_INTEGER_OPERAND, line, context);
    }
    context.stack.push({ isInteger: true, isNonZero: false });
    context.stack.push({ isInteger: true, isNonZero: false });
    context.stack.push({ isInteger: true, isNonZero: false });
    var pointerName = '__pointerCycle_pointerToIncrement' + line.lineNumber;
    var startPositionName = '__pointerCycle_startPosition' + line.lineNumber;
    var endPositionName = '__pointerCycle_endPosition' + line.lineNumber;
    return (0, compiler_1.parseSegment)([
        "local int ".concat(pointerName),
        "local int ".concat(startPositionName),
        "local int ".concat(endPositionName),
        "localSet ".concat(endPositionName),
        "localSet ".concat(startPositionName),
        "localSet ".concat(pointerName),
        "localGet ".concat(pointerName),
        "localGet ".concat(pointerName),
        "load",
        'push WORD_SIZE',
        'add',
        'store',
        "localGet ".concat(pointerName),
        'load',
        "localGet ".concat(endPositionName),
        'greaterThan',
        'if void',
        " localGet ".concat(pointerName),
        " localGet ".concat(startPositionName),
        " store",
        'ifEnd',
    ], context);
};
exports["default"] = cycle;
