"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
var errors_1 = require("../errors");
var _module = function (line, context) {
    if (!line.arguments[0]) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.MISSING_ARGUMENT, line, context);
    }
    if (line.arguments[0].type === "literal" /* ArgumentType.LITERAL */) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.EXPECTED_IDENTIFIER, line, context);
    }
    context.blockStack.push({
        hasExpectedResult: false,
        expectedResultIsInteger: false,
        isModuleBlock: true,
        isGroupBlock: false,
        isLoop: false,
        isConditionBlock: false,
        isFunctionBlock: false
    });
    return {
        byteCode: [],
        context: __assign(__assign({}, context), { namespace: __assign(__assign({}, context.namespace), { moduleName: line.arguments[0].value }) })
    };
};
exports["default"] = _module;
