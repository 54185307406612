"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var memory = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    var memory = new Map(context.namespace.memory);
    var defaultValue = 0;
    if (!line.arguments[0] || !line.arguments[1]) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.MISSING_ARGUMENT, line, context);
    }
    if (line.arguments[0].type !== "identifier" /* ArgumentType.IDENTIFIER */) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.EXPECTED_IDENTIFIER, line, context);
    }
    if (line.arguments[1].type === "literal" /* ArgumentType.LITERAL */) {
        defaultValue = line.arguments[1].value;
    }
    else if (line.arguments[1].type === "identifier" /* ArgumentType.IDENTIFIER */ && /&(\S+)\.(\S+)/.test(line.arguments[1].value)) {
        // Do nothing
        // Intermodular references are resolved later
    }
    else if (line.arguments[1].type === "identifier" /* ArgumentType.IDENTIFIER */ && line.arguments[1].value[0] === '&') {
        var memoryItem = memory.get(line.arguments[1].value.substring(1));
        if (!memoryItem) {
            throw (0, errors_1.getError)(errors_1.ErrorCode.UNDECLARED_IDENTIFIER, line, context);
        }
        defaultValue = memoryItem.byteAddress;
    }
    else if (line.arguments[1].type === "identifier" /* ArgumentType.IDENTIFIER */) {
        var constant = context.namespace.consts[line.arguments[1].value];
        if (!constant) {
            throw (0, errors_1.getError)(errors_1.ErrorCode.UNDECLARED_IDENTIFIER, line, context);
        }
        defaultValue = constant.value;
    }
    if (/(\S+)\[(\d+)\]/.test(line.arguments[0].value)) {
        var _a = __read(line.arguments[0].value.match(/(\S+)\[(\d+)\]/), 3), memoryIdentifier = _a[1], offset = _a[2];
        var memoryItem = memory.get(memoryIdentifier);
        if (memoryItem && memoryItem["default"] instanceof Map) {
            memoryItem["default"].set(parseInt(offset, 10), defaultValue);
        }
    }
    else {
        var memoryItem = memory.get(line.arguments[0].value);
        if (memoryItem) {
            memoryItem["default"] = defaultValue;
        }
    }
    return { byteCode: [], context: __assign(__assign({}, context), { namespace: __assign(__assign({}, context.namespace), { memory: memory }) }) };
};
exports["default"] = memory;
