"use strict";
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var compiler_1 = require("../compiler");
var fallingEdge = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    var operand = context.stack.pop();
    if (!operand) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSUFFICIENT_OPERANDS, line, context);
    }
    context.stack.push({ isInteger: true, isNonZero: false });
    var currentValueName = '__fallingEdgeDetector_currentValue' + line.lineNumber;
    var previousValueName = '__fallingEdgeDetector_previousValue' + line.lineNumber;
    return (0, compiler_1.parseSegment)([
        "int ".concat(previousValueName, " 0"),
        "local int ".concat(currentValueName),
        "localSet ".concat(currentValueName),
        "localGet ".concat(currentValueName),
        "push &".concat(previousValueName),
        'load',
        'lessThan',
        'if int',
        'push 1',
        'else',
        'push 0',
        'ifEnd',
        "push &".concat(previousValueName),
        "localGet ".concat(currentValueName),
        'store',
    ], context);
};
exports["default"] = fallingEdge;
