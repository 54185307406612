"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var instructionHelpers_1 = require("../wasmUtils/instructionHelpers");
var compiler_1 = require("../compiler");
var store = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    var operand1Value = context.stack.pop();
    var operand2Address = context.stack.pop();
    if (!operand1Value || !operand2Address) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSUFFICIENT_OPERANDS, line, context);
    }
    if (!operand2Address.isInteger) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.EXPECTED_INTEGER_OPERAND, line, context);
    }
    if (operand2Address.isSafeMemoryAddress) {
        return { byteCode: operand1Value.isInteger ? (0, instructionHelpers_1.i32store)() : (0, instructionHelpers_1.f32store)(), context: context };
    }
    else {
        context.stack.push(operand2Address);
        context.stack.push(operand1Value);
        var tempAddressVariableName = '__storeAddress_temp_' + line.lineNumber;
        var tempValueVariableName = '__storeValue_temp_' + line.lineNumber;
        // Memory overflow protection.
        var ret = (0, compiler_1.parseSegment)(__spreadArray([
            "local int ".concat(tempAddressVariableName),
            "local ".concat(operand1Value.isInteger ? 'int' : 'float', " ").concat(tempValueVariableName),
            "localSet ".concat(tempValueVariableName),
            "localSet ".concat(tempAddressVariableName),
            "localGet ".concat(tempAddressVariableName),
            "push ".concat(context.memoryByteSize - 1),
            'greaterThan',
            'if int',
            "push 0",
            'else',
            "localGet ".concat(tempAddressVariableName),
            'ifEnd',
            "localGet ".concat(tempValueVariableName)
        ], __read((operand1Value.isInteger ? (0, instructionHelpers_1.i32store)() : (0, instructionHelpers_1.f32store)()).map(function (wasmInstruction) {
            return "wasm ".concat(wasmInstruction);
        })), false), context);
        // Because the wasm instruction doesn't update the stack we need to pop the operands manually.
        context.stack.pop();
        context.stack.pop();
        return ret;
    }
};
exports["default"] = store;
