"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var consts_1 = require("../consts");
var memory = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    var memory = new Map(context.namespace.memory);
    var wordAlignedAddress = (0, utils_1.calculateWordAlignedSizeOfMemory)(memory);
    if (!line.arguments[0]) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.MISSING_ARGUMENT, line, context);
    }
    if (line.arguments[0].type === "literal" /* ArgumentType.LITERAL */) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.EXPECTED_IDENTIFIER, line, context);
    }
    var defaultValue = 0;
    if (!line.arguments[1]) {
        defaultValue = 0;
    }
    else if (line.arguments[1].type === "literal" /* ArgumentType.LITERAL */) {
        defaultValue = line.arguments[1].value;
    }
    else if (line.arguments[1].type === "identifier" /* ArgumentType.IDENTIFIER */ && /&(\S+)\.(\S+)/.test(line.arguments[1].value)) {
        // Do nothing
        // Intermodular references are resolved later
    }
    else if (line.arguments[1].type === "identifier" /* ArgumentType.IDENTIFIER */ && line.arguments[1].value[0] === '&') {
        var memoryItem = memory.get(line.arguments[1].value.substring(1));
        if (!memoryItem) {
            throw (0, errors_1.getError)(errors_1.ErrorCode.UNDECLARED_IDENTIFIER, line, context);
        }
        defaultValue = memoryItem.byteAddress;
    }
    else if (line.arguments[1].type === "identifier" /* ArgumentType.IDENTIFIER */ && line.arguments[1].value[0] === '$') {
        var memoryItem = memory.get(line.arguments[1].value.substring(1));
        if (!memoryItem) {
            throw (0, errors_1.getError)(errors_1.ErrorCode.UNDECLARED_IDENTIFIER, line, context);
        }
        defaultValue = memoryItem.wordAlignedSize;
    }
    else if (line.arguments[1].type === "identifier" /* ArgumentType.IDENTIFIER */) {
        var constant = context.namespace.consts[line.arguments[1].value];
        if (!constant) {
            throw (0, errors_1.getError)(errors_1.ErrorCode.UNDECLARED_IDENTIFIER, line, context);
        }
        defaultValue = constant.value;
    }
    memory.set(line.arguments[0].value, {
        numberOfElements: 1,
        elementWordSize: 4,
        wordAlignedAddress: context.startingByteAddress / consts_1.GLOBAL_ALIGNMENT_BOUNDARY + wordAlignedAddress,
        wordAlignedSize: 1,
        byteAddress: context.startingByteAddress + wordAlignedAddress * consts_1.GLOBAL_ALIGNMENT_BOUNDARY,
        id: line.arguments[0].value,
        "default": defaultValue,
        type: line.instruction,
        isPointer: line.instruction === 'int*' ||
            line.instruction === 'float*' ||
            line.instruction === 'int**' ||
            line.instruction === 'float**',
        isPointingToInteger: line.instruction === 'int*' || line.instruction === 'int**',
        isPointingToPointer: line.instruction === 'int**' || line.instruction === 'float**',
        isInteger: line.instruction === 'int' ||
            line.instruction === 'int*' ||
            line.instruction === 'float*' ||
            line.instruction === 'int**' ||
            line.instruction === 'float**'
    });
    return { byteCode: [], context: __assign(__assign({}, context), { namespace: __assign(__assign({}, context.namespace), { memory: memory }) }) };
};
exports["default"] = memory;
