"use strict";
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var _function = function (line, context) {
    if ((0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    context.blockStack.push({
        isFunctionBlock: true,
        isConditionBlock: false,
        isGroupBlock: false,
        isLoop: false,
        isModuleBlock: false,
        expectedResultIsInteger: false,
        hasExpectedResult: false
    });
    return { byteCode: [], context: context };
};
exports["default"] = _function;
